import React from 'react';
import styled from 'styled-components';
import { Colors } from '../utils/colors';
import 'firebase/analytics'

const ErrorPage = () => {
	return ( 
		<Container>
			<Title>Error</Title>
		</Container>
	 );
}
 
export default ErrorPage;

const Container = styled.div`
display: flex;
justify-content: center;
align-items: center;
flex: 1;
`;

const Title = styled.h1`
font-size: 40px;
color: ${Colors.secondary};
`;